<template>
  <v-card>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols="6" md="4">
            <v-text-field v-model="admin.firstName"
                          label="First Name"
                          required
                          :rules="[v => !!v || 'First Name is required']"
                          outlined
                          dense></v-text-field>
          </v-col>

          <v-col cols="6" md="4">
            <v-text-field v-model="admin.lastName"
                          label="Last Name"
                          required
                          :rules="[v => !!v || 'Last Name is required']"
                          outlined
                          dense></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="4">
            <v-text-field v-model="admin.email"
                          label="Email"
                          required
                          :rules="emailRules"
                          outlined
                          @blur="checkEmailExists"
                          dense></v-text-field>
          </v-col>




        </v-row>

      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" @click="save">Done</v-btn>
      <v-btn text @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import store from '@/store';

export default {
  name: "AdminDetails",
  props:{admin: Object},
  data: vm => {
    return {
      emailIsUnique: true,
      valid: true,
      emailRules: [
        v => !!v || 'Email is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Email must be valid',
        v => vm.emailIsUnique || 'This email is already in use'
      ]
    }
  },
  methods:{
    checkEmailExists(){
      const vm = this;

      store.dispatch('validation/IsEmailUnique', {email: vm.admin.email})
        .then(function(response){
          vm.emailIsUnique = response;
          vm.$refs.form.validate();
        });

    },
    save(){
      let isValid = this.$refs.form.validate();
      if(!isValid) return;

      this.$emit('save');
    },
    cancel(){
      this.$emit('cancel');
    }
  }
}
</script>

<style scoped>

</style>
