<template>
  <v-layout class="fill-height">
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      clipped
      :color="$vuetify.breakpoint.lgAndUp ? 'surface': ''"
      :right="$vuetify.rtl"
      width="260"
      class="nav-drawer"
    >
      <!-- Navigation menu -->
      <main-menu :menu="menu" class="mt-2" />
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      flat
      color="surface"
      class="app-bar-full"
      clipped-left
      height="100"
    >

      <v-card
        class="flex-grow-1 d-flex pa-1"
        tile
      >
        <div class="d-flex flex-grow-1 align-center">
          <v-app-bar-nav-icon class="d-lg-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

          <div class="d-flex px-1 align-center">
            <v-img src="/images/IngramScholarsLogo2.jpg" :height="logoHeight"></v-img>

          </div>

          <v-spacer></v-spacer>

          <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">

            <toolbar-notifications v-if="showNotification" />

          </div>

          <toolbar-user />
        </div>
      </v-card>
    </v-app-bar>


    <v-main class="bg-white">
      <v-container :fluid="!isContentBoxed" class="pt-3">
        <router-view class="py-2 fill-height test23"></router-view>
      </v-container>
    </v-main>


    <v-footer app>
      <div class="overline">
        Copyright © 2021 <a href="#" target="_blank">Ingram Scholars Program</a>, All rights Reserved
      </div>
      <v-spacer></v-spacer>
      <div class="overline">
      </div>
    </v-footer>
  </v-layout>
</template>

<script>
import config from '@/configs'
import MainMenu from '@/components/navigation/MainMenu'
import ToolbarUser from '@/components/ingram/ToolbarUser'
import ToolbarNotifications from '@/components/toolbar/ToolbarNotifications'
import menu from './adminMenu'
import Vuetify from "vuetify";

export default {
  name: 'AdminLayout',
  components: {
    MainMenu,
    ToolbarUser,
    ToolbarNotifications
  },
  props: {
    isDemo: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    logoHeight(){
      console.log(this.$vuetify)
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '50px'
        case 'sm': return '50px'
        case 'md': return '50px'
        case 'lg': return '100px'
        default: return '100px'
      }
    }
  },
  data() {
    return {
      menu,
      config,
      drawer: null,
      isContentBoxed: false,
      showNotification: false
    }
  },
  mounted(){
    console.log(this.$vuetify)
  }
}
</script>

<style lang="scss">
.app-bar-full {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0;
  }
}

.bg-white{
  background-color:white;
}
.nav-drawer{

}

@media print
{
  .no-print, .no-print *
  {
    visibility: hidden !important;
  }

  .yes-print, .yes-print *
  {
    visibility: visible !important;
  }
}
</style>
