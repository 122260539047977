<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Students</h1>
    </div>

    <v-row>
      <v-col cols="12" >
        <v-card>
          <v-card-text style="overflow: scroll">
            <admin-list :admins="admins" @remove="remove"></admin-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import AdminList from "@/components/ingram/admin/AdminList";
import Swal from "sweetalert2";

export default {
  name: "Admins",
  components:{AdminList},
  data(){
    return {
      admins:[]
    }
  },
  methods:{
    remove(item){
      const vm = this;
      httpClient.delete(`/admin/${item.id}`)
        .then(function(result){
          if(result.data.success){
            Swal.fire(`${item.firstName} ${item.lastName} Deleted`, '', 'success');
            vm.refresh();
          }else{
            Swal.fire(`Unable to delete ${item.firstName} ${item.lastName}`, '', 'error')
          }

        })
        .catch(function(error){

        });
    },
    refresh(){
      const vm = this;

      httpClient.get('/admin')
        .then(function(response){
          vm.admins = response.data;
        })
        .catch(function(error){

        });
    }
  },
  mounted(){
    this.refresh();
  }
}
</script>

<style scoped>

</style>
