<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Create Student</h1>
    </div>
    <student-details :student="student" :showArchived="false" @save="save" @cancel="cancel"></student-details>
  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import StudentDetails from "@/components/ingram/student/StudentDetails";

export default {
  name: "CreateStudent",
  components:{StudentDetails},
  data(){
    return{
      student:{},
      emailExists: false,
    }
  },
  methods:{
    save(){
      const vm = this;
      httpClient.post('/student', vm.student)
        .then(function(response){
          if(response.data.success){
            vm.$root.$emit('saveSuccess', {message: 'Your changes were saved'});
            vm.$router.push('/admin/students');
          }
          else{
            let message = response.data.message || 'Student could not be saved';
            vm.$root.$emit('saveFailed', {message: 'Student could not be saved'});
          }

        })
        .catch(function(response){
          vm.$root.$emit('saveFailed', {message: 'Student could not be saved'});
        });
    },
    cancel(){
      this.$router.push('/admin/students');
    }
  },
  mounted(){
    const vm = this;

  }
}
</script>

<style scoped>

</style>
