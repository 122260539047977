export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/admin'  }
  ]
}, {
  text: 'Things I Can Do',
  key: 'menu.others',
  items: [
    { icon: 'mdi-account-group', key: 'menu.blank', text: 'Students', link: '/admin/students' },
    { icon: 'mdi-shape', key: 'menu.blank', text: 'Categories', link: '/admin/categories' },
    { icon: 'mdi-office-building', key: 'menu.blank', text: 'Organizations', link: '/admin/organizations' },
    { icon: 'mdi-shield-account', key: 'menu.blank', text: 'Administrators', link: '/admin/admins' },
    { icon: 'mdi-chart-bar', key: 'menu.blank', text: 'Reports', items:[
        {text: 'Monthly Service', link: '/reports/monthly-service'},
        {text: 'Student Service Summary', link: '/reports/student-service-summary'},
        {text: 'Service Analysis', link: '/reports/service-analysis'}
      ]},

  ]
}]



/*
export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '#'  }
  ]
}, {
  text: 'Other',
  key: 'menu.others',
  items: [
    { icon: 'mdi-file-outline', key: 'menu.blank', text: 'Blank Page', link: '' },
    { key: 'menu.levels', text: 'Menu Levels',
      items: [
        { text: 'Menu Levels 2.1' },
        { text: 'Menu Levels 2.2',
          items: [
            { text: 'Menu Levels 3.1' },
            { text: 'Menu Levels 3.2' }
          ]
        }
      ]
    },
    { key: 'menu.disabled', text: 'Menu Disabled', disabled: true }
  ]
}]
 */
