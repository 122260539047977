<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Students</h1>
    </div>

    <v-row>
      <v-col cols="12" >
        <v-card>
          <v-card-text >
          <student-list :students="students" @resendEmail="resendEmail"></student-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import StudentList from "@/components/ingram/student/StudentList";

export default {
  name: "StudentRoster",
  components:{StudentList},
  data(){
    return {
      students:[]
    }
  },
  methods:{
    resendEmail(id){
      const vm = this;

      httpClient.post(`/student/resend-email/${id}`)
      .then(function(response){
        vm.$root.$emit('saveSuccess', {message: 'Email Sent.'});
      })
      .catch(function(error){

      });

    }
  },
  mounted(){
    const vm = this;

    httpClient.get('/student?includeArchived=true')
    .then(function(response){
      vm.students = response.data;
    })
    .catch(function(error){

    });
  }
}
</script>

<style scoped>

</style>
