<template>
<div>
  <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
    <h1 class="text-h4">Service Analysis Report</h1>
  </div>

  <div>
    <v-card>
      <v-card-text >
        <v-row>
          <v-col cols="3">
            <v-menu ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  autocomplete="off"

                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>

              </template>
              <v-date-picker
                v-model="dates"
                range
                no-title
                label="Date Range"
                outlined
                dense
              ></v-date-picker>

            </v-menu>
          </v-col>

          <v-col
            cols="6"
            sm="6"
          >
            <v-select
              v-model="selectedStudents"
              :items="students"
              item-value="token"
              clearable
              label="Students"
              multiple



            >

              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggleSelectedStudents"
                >
                  <v-list-item-action>
                    <v-icon :color="selectedStudents.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Select All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>

              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        {{item.firstName}} {{item.lastName}}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template slot="selection" slot-scope="data">
                <v-chip
                  :input-value="data.selected"
                  class="chip--select-multi"
                >
                  {{data.item.firstName}} {{data.item.lastName}}
                </v-chip>

              </template>
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-btn color="primary" @click="runReport">View Report</v-btn>
            <v-btn @click="print">Print</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>

  <div id="print-report" class="mt-15" v-if="showReport">
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-card-title>
            Date Range
          </v-card-title>
          <v-card-text>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{reportData.startDate}} - {{reportData.endDate}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>


          </v-card-text>

        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card>
          <v-card-title>
            Total Hours
          </v-card-title>
          <v-card-text>
            <v-list-item >
              <v-list-item-content>
                <v-list-item-title>
                  {{reportData.totalHours}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>


          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="3">
        <v-card>
          <v-card-title>
            Students ({{reportData.studentCount}})
          </v-card-title>
          <v-card-text>
            <v-list-item v-for="student in reportData.students" :key="student">
              <v-list-item-content>
                <v-list-item-title>{{student}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>

        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card>
          <v-card-title>
            Organizations ({{reportData.organizationCount}})
          </v-card-title>
          <v-card-text>
            <v-list-item v-for="org in reportData.organizations" :key="org">
              <v-list-item-content>
                <v-list-item-title>{{org}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>

        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card>
          <v-card-title>
            Categories ({{reportData.categoryCount}})
          </v-card-title>
          <v-card-text>
            <v-list-item v-for="cat in reportData.categories" :key="cat">
              <v-list-item-content>
                <v-list-item-title>{{cat}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>

        </v-card>
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12" >
        <v-card>
          <v-card-title>Service Detail</v-card-title>
          <v-card-text>
            <report-service-list :service="reportData.serviceLogData" ></report-service-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</div>
</template>

<script>
import httpClient from "@/plugins/axios";
import ReportServiceList from "@/components/ingram/service/ReportServiceList";

export default {
  name: "ServiceAnalysis",
  components: {ReportServiceList},
  computed: {
    dateRangeText () {
      let formattedDates = this.dates.map(x => {return this.$luxon(x, "date_short");});
      return formattedDates.join(' - ')
    },
    selectedAllStudents () {
      return this.selectedStudents.length === this.students.length
    },
    selectSomeStudents () {
      return this.selectedStudents.length > 0 && !this.selectedAllStudents
    },
    icon () {
      if (this.selectedAllStudents) return 'mdi-close-box'
      if (this.selectSomeStudents) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  data(){
    return {
      menu1: false,
      showReport: false,
      serviceItemsPerPage: 999,
      dates:[],
      students:[],
      selectedStudents:[],
      reportData:{}
    }
  },
  methods:{
    print(){
      this.$htmlToPaper('print-report');
    },
    loadStudents(){
      const vm = this;

      httpClient.get('/student')
        .then(function(response){
          vm.students = response.data;
        })
    },
    runReport(){
      const vm = this;
      const request = {
        startDate: vm.dates[0],
        endDate: vm.dates[1],
        studentTokens: vm.selectedStudents
      };

      httpClient.post('/reports/service-analysis', request)
      .then(function(response){
        vm.reportData = response.data;
        vm.showReport = true;
      });
    },
    toggleSelectedStudents () {
      this.$nextTick(() => {
        if (this.selectedAllStudents) {
          this.selectedStudents = []
        } else {
          this.selectedStudents = this.students.slice().map(x => x.token);
        }
      })
    }
  },
  mounted(){
    this.loadStudents();
  }
}
</script>

<style scoped>

</style>
