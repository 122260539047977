<template>
<div>
  <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
    <h1 class="text-h4">Monthly Service Report</h1>
  </div>

  <div>
    <v-card>
      <v-card-text >
        <v-row>
          <v-col cols="3">
            <v-menu ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  autocomplete="off"

                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>

              </template>
              <v-date-picker
                v-model="dates"
                range
                no-title
                label="Date Range"
                outlined
                dense
              ></v-date-picker>

            </v-menu>
          </v-col>

          <v-col
            cols="6"
            sm="6"
          >
            <v-select
              v-model="selectedStudents"
              :items="students"
              item-value="token"
              clearable
              label="Students"
              multiple



            >

              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggleSelectedStudents"
                >
                  <v-list-item-action>
                    <v-icon :color="selectedStudents.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Select All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>

              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        {{item.firstName}} {{item.lastName}}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template slot="selection" slot-scope="data">
                <v-chip
                  :input-value="data.selected"
                  class="chip--select-multi"
                >
                  {{data.item.firstName}} {{data.item.lastName}}
                </v-chip>

              </template>
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-btn color="primary" @click="runReport">View Report</v-btn>
            <v-btn @click="print">Print</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>

  <div id="print-report" class="mt-15">
    <div v-for="item in reportData" :key="item.studentName" class="mt-15" style="page-break-before: always;">

      <v-simple-table >
        <template v-slot:default>
          <thead>
          <tr>
            <th colspan="6" class="text-h5">
              {{item.studentName}} - {{item.totalHours}} hours
              <br/>
              <span class="print-only-inline">{{item.period}}</span>
            </th>
          </tr>
          </thead>
          <thead>
          <tr>
            <th>Organization / Contact</th>
            <th style="width:600px;">Description</th>
            <th>Date</th>
            <th>Hours</th>
            <th>Type</th>
            <th>Category</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="service in item.service" :key="service.id">
            <td>
              {{service.organization}}
              <br/>
              {{service.contact}}
            </td>
            <td>{{service.description}}</td>
            <td>{{service.date | luxon({output: {format:'MM/dd/yyyy'} }) }}</td>
            <td>{{service.hours}}</td>
            <td>{{service.type}}</td>
            <td>{{service.category}}</td>
          </tr>
          </tbody>

        </template>
      </v-simple-table>
    </div>


  </div>

</div>
</template>

<script>
import httpClient from "@/plugins/axios";

export default {
  name: "MonthlyServiceReport",
  data(){
    return{
      menu1: false,
      showReport: false,
      dates:[],
      students:[],
      selectedStudents:[],
      reportData:[],
    }
  },
  computed:{
    dateRangeText () {
      let formattedDates = this.dates.map(x => {return this.$luxon(x, "date_short");});
      return formattedDates.join(' - ')
    },
    selectedAllStudents () {
      return this.selectedStudents.length === this.students.length
    },
    selectSomeStudents () {
      return this.selectedStudents.length > 0 && !this.selectedAllStudents
    },
    icon () {
      if (this.selectedAllStudents) return 'mdi-close-box'
      if (this.selectSomeStudents) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods:{
    loadStudents(){
      const vm = this;

      httpClient.get('/student')
        .then(function(response){
          vm.students = response.data;
        })
    },
    toggleSelectedStudents () {
      this.$nextTick(() => {
        if (this.selectedAllStudents) {
          this.selectedStudents = []
        } else {
          this.selectedStudents = this.students.slice().map(x => x.token);
        }
      })
    },
    runReport(){
      const vm = this;

      const request = {
        startDate: vm.dates[0],
        endDate: vm.dates[1],
        studentTokens: vm.selectedStudents
      };

      httpClient.post('/reports/monthly-service', request)
      .then(function(response){
        vm.reportData = response.data;
      });
    },
    print(){
      this.$htmlToPaper('print-report');
    }
  },
  mounted(){
    this.loadStudents();
  }
}
</script>

<style scoped>
  .print-only, .print-only-inline{
    display:none;
  }

  @media print{
    .print-only{
      display:block;
    }

    .print-only-inline{
      display:inline;
    }
  }
</style>
