<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Modify Organization</h1>
    </div>
    <organization-details :organization="organization" @save="save" @cancel="cancel" @dirty="dirty"></organization-details>
  </div>
</template>

<script>
import OrganizationDetails from "@/components/ingram/organization/OrganizationDetails";
import httpClient from "@/plugins/axios";
import Swal from 'sweetalert2';
import store from "@/store";
export default {
  name: "EditOrganization",
  components: {OrganizationDetails},
  data(){
    return{
      hasChanges: false,
      organization:{}
    }
  },
  methods:{
    save(){
      const vm = this;

      httpClient.post('/organization', vm.organization)
        .then(function(response){
          if(response.data.success){
            vm.hasChanges = false;
            vm.$root.$emit('saveSuccess', {message: 'Your changes were saved'});
            store.dispatch("app/getDomainData");
            vm.$router.push('/admin/organizations');
          }else{
            vm.$root.$emit('saveFailed', {message: response.data.message});
          }

        })
        .catch(function(response){
          vm.$root.$emit('saveFailed', {message: 'Organization could not be saved'});
        });
    },
    cancel(){
      this.$router.push('/admin/organizations');
    },
    dirty(){
      this.hasChanges = true;
    }
  },
  mounted(){
    const vm = this;
    vm.id = this.$route.params.id;

    httpClient.get(`/organization/${vm.id}`)
      .then(function(response){
        vm.organization = response.data;
      })
      .catch(function(error){
        console.log(error);
      });
  },
  beforeRouteLeave(to, from, next){
    const vm = this;
    if(this.hasChanges){
      Swal.fire({
        title: 'You have unsaved changes, save them now?',
        icon:'warning',
        showDenyButton: true,
        showCancelButton: false,
        showCloseButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No'
      }).then((result) => {
        if(result.isConfirmed){
          vm.save();

        }else{
          next()
        }
      })
        .catch(() => next(false));
    }
    next();
  }
}
</script>

<style scoped>

</style>
