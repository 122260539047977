<template>
<div>
  <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
    <h1 class="text-h4">Student Service Summary Report</h1>
  </div>

  <div>
    <v-card>
      <v-card-text >
      <v-row>
        <v-col cols="3" sm="3">
          <v-select
            v-model="year"
            :items="yearsList"
            label="Service Year"
            chips
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          sm="6"
        >
          <v-select
            v-model="selectedStudents"
            :items="students"
            item-value="token"
            clearable
            label="Students"
            multiple
            chips
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="toggleSelectedStudents"
              >
                <v-list-item-action>
                  <v-icon :color="selectedStudents.length > 0 ? 'indigo darken-4' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      {{item.firstName}} {{item.lastName}}
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template slot="selection" slot-scope="data">
              <v-chip
                :input-value="data.selected"
                class="chip--select-multi"
              >
                {{data.item.firstName}} {{data.item.lastName}}
              </v-chip>

            </template>
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-btn color="primary" @click="runReport">View Report</v-btn>
          <v-btn @click="print">Print</v-btn>
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>
  </div>
  <div id="print-report" class="mt-15">
    <v-card v-for="data in reportData" :key="data.token" class="mt-15 yes-print" style="page-break-before: always;">
      <v-card-title>
        <div>
        <v-row>
        <div class="text-h3">
          {{data.firstName}} {{data.lastName}}
        </div>
        </v-row>
        <v-row>
        <div class="text-h5">
          All Service Summary Report
        </div>
        </v-row>
        </div>
      </v-card-title>
      <v-divider class="ma-10"></v-divider>
      <v-card-text>
        <v-row justify="center" align="stretch">
          <v-col cols="6" md="4">

            <v-card class="pa-3 text-center">
              <v-card-title>Category Breakdown</v-card-title>
              <div style="position:relative;height:300px; width:400px;" class="justify-center">
                <apexcharts v-if="ready" type="pie" :options="getChartOptions(data).cat" :series="getChartSeriesData(data).cat" ref="chart"></apexcharts>
              </div>
            </v-card>
          </v-col>

          <v-col cols="6" md="4">

            <v-card class="pa-3 text-center">
              <v-card-title>Organization Breakdown</v-card-title>
              <div style="position:relative;height:300px; width:400px;" class="justify-center">
                <apexcharts v-if="ready" type="pie" :options="getChartOptions(data).org" :series="getChartSeriesData(data).org" ref="chart"></apexcharts>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="ma-10"></v-divider>
        <v-row>
          <v-col cols="12" >
            <v-card>
              <report-service-list :service="data.serviceLogData" ></report-service-list>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </div>

</div>
</template>

<script>
import httpClient from "@/plugins/axios";
import VueApexCharts from 'vue-apexcharts'
import ReportServiceList from "@/components/ingram/service/ReportServiceList";
export default {
  name: "StudentServiceSummaryReport",
  components:{apexcharts: VueApexCharts, ReportServiceList},
  data(){
    return {
      ready:true,
      serviceItemsPerPage: 999,
      year: null,
      years:[2020, 2021, 2022],
      students:[],
      selectedStudents:[],
      reportData:[],
    }
  },
  computed: {
    yearsList(){
      const list = [];
      for(let i = 2020; i <= new Date().getFullYear(); i++){
        list.push(i);
      }
      return list;
    },
    selectedAllStudents () {
      return this.selectedStudents.length === this.students.length
    },
    selectSomeStudents () {
      return this.selectedStudents.length > 0 && !this.selectedAllStudents
    },
    icon () {
      if (this.selectedAllStudents) return 'mdi-close-box'
      if (this.selectSomeStudents) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods:{
    print(){
      this.$htmlToPaper('print-report');
    },
    loadStudents(){
      const vm = this;

      httpClient.get('/student')
        .then(function(response){
          vm.students = response.data;
        })
    },
    runReport(){
      const vm = this;

      httpClient.post('/reports/student-service-summary', {studentTokens: vm.selectedStudents, year: vm.year})
      .then(function(response){
        vm.reportData = response.data;



        vm.$nextTick(function () {vm.ready = true;});
      })
    },
    getService(reportDataItem){
      return reportDataItem.serviceLogData;
    },
    getChartSeriesData(reportDataItem){
      const vm = this;
      const currentSize = this.$vuetify.breakpoint.name;
      const catData = reportDataItem.categoryChartData;
      const orgData = reportDataItem.organizationChartData;

      const catSeries = catData.map(x => x.hours);
      const orgSeries = orgData.map(x => x.hours);

      return {org: orgSeries, cat:catSeries};

    },
    getChartOptions(reportDataItem){
      const vm = this;
      const currentSize = this.$vuetify.breakpoint.name;
      const catData = reportDataItem.categoryChartData;
      const orgData = reportDataItem.organizationChartData;

      const catLabels = catData.map(x => x.category);
      const orgLabels = orgData.map(x => x.organization);

      let catOptions = {
        legend:{
          position: 'bottom',
        },
        plotOptions:{
          pie:{
            customScale: 1,
          }
        },
        chart:{
          redrawOnParentResize: false,
          width: '300'
        },
        labels:catLabels,
      };

      let orgOptions = {
        legend:{
          position: 'bottom',
        },
        plotOptions:{
          pie:{
            customScale: 1,
          }
        },
        chart:{
          redrawOnParentResize: false,
          width: '300'
        },
        labels:orgLabels,
      };

      if(currentSize === 'xs' || currentSize==='sm'){
        catOptions.legend.position = 'bottom';
        orgOptions.legend.position = 'bottom';
      }

      return {org:orgOptions, cat:catOptions};

    },
    toggleSelectedStudents () {
      this.$nextTick(() => {
        if (this.selectedAllStudents) {
          this.selectedStudents = []
        } else {
          this.selectedStudents = this.students.slice().map(x => x.token);
        }
      })
    }
  },
  mounted(){
    this.loadStudents();
  }
}
</script>

<style scoped>
@media print
{
  .no-print, .no-print *
  {
    visibility: hidden !important;
  }

  .yes-print, .yes-print *
  {
    visibility: visible !important;
  }
}
</style>
