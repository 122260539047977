<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Organizations</h1>
    </div>

    <v-row>
      <v-col cols="12" >
        <v-card>
          <v-card-text style="overflow: scroll">
            <organization-list :organizations="organizations" @remove="remove"></organization-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import Swal from 'sweetalert2';
import OrganizationList from "@/components/ingram/organization/OrganizationList";
import store from "@/store";

export default {
  name: "Organizations",
  components:{OrganizationList},
  data(){
    return {
      organizations:[]
    }
  },
  methods:{
    remove(item){
      const vm = this;
      httpClient.delete(`/organization/${item.id}`)
        .then(function(result){
          if(result.data.success){
            Swal.fire(`${item.name} Deleted`, '', 'success');
            store.dispatch("app/getDomainData");
            vm.refresh();
          }else{
            Swal.fire(`Unable to delete ${item.name}`, '', 'error')
          }

        })
        .catch(function(error){

        });
    },
    refresh(){
      const vm = this;

      httpClient.get('/organization')
        .then(function(response){
          vm.organizations = response.data;
        })
        .catch(function(error){
          vm.$root.$emit('saveFailed', 'Unable to contact server, please try again');
        });
    }
  },
  mounted(){
    this.refresh();
  }
}
</script>

<style scoped>

</style>
