<template>
  <v-card>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols="6" md="4">
            <v-text-field v-model="category.name"
                          label="Category Name"
                          required
                          :rules="[v => !!v || 'Category Name is required']"
                          outlined
                          dense></v-text-field>
          </v-col>

        </v-row>



      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" @click="save">Done</v-btn>
      <v-btn text @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CategoryDetails",
  props:['category'],
  data(){
    return {
      valid: true,
    }
  },
  methods:{
    save(){
      let isValid = this.$refs.form.validate();
      if(!isValid) return;

      this.$emit('save');
    },
    cancel(){
      this.$emit('cancel');
    }
  }
}
</script>

<style scoped>

</style>
