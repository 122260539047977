<template>
<div>
  <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
    <h1 class="text-h4">Student Information</h1>
  </div>

    <v-tabs v-model="tab">
      <v-tab>Student Details</v-tab>
      <v-tab>Service</v-tab>
      <v-tab>Documents</v-tab>
      <v-tab>Summer Project</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <student-details :student="student" :showArchived="true" @save="save" @cancel="cancel"></student-details>
      </v-tab-item>

      <v-tab-item>
       <service-list :service="student.service"></service-list>
      </v-tab-item>

      <v-tab-item>
        <document-list class="ma-5" :documents="documents" @change="documentChanged" @remove="remove"  @search="searchDocuments" @add="add" :can-add=false :can-delete=false></document-list>
      </v-tab-item>

      <v-tab-item>
        <summer-project :project="student.summerProject"></summer-project>
      </v-tab-item>

    </v-tabs-items>
  <v-snackbar
    top
    :timeout="deletedAlert.timeout"
    v-model="deletedAlert.visible"
  >
    {{deletedAlert.message}}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="red"
        text
        v-bind="attrs"
        @click="undoLastDelete"
      >
        Undo
      </v-btn>
    </template>
  </v-snackbar>

</div>
</template>

<script>
import httpClient from "@/plugins/axios";
import StudentDetails from "@/components/ingram/student/StudentDetails";
import ServiceList from "@/components/ingram/service/ServiceList";
import DocumentList from "@/components/ingram/documents/DocumentList";
import SummerProject from "@/components/ingram/SummerProject/SummerProject";
import store from '@/store';
import Swal from "sweetalert2";
import {mapState} from "vuex";

export default {
  name: "EditStudent",
  components:{StudentDetails, ServiceList, DocumentList,SummerProject},
  data(){
    return{
      tab: null,
      showArchived: true,
      student:{},
      documents:[],
      deletedAlert: {
        visible: false,
        timeout: 5000,
        message: ''
      },
    }
  },
  computed:{
    ...mapState('app', ["organizations", "categories", "studentToken"])
  },
  methods:{
    add(files){
      if(Array.isArray(files)){
        this.uploadDocuments(files)
      }else{
        this.addText(files);
      }
    },
    addText(file){
      const vm = this;

      httpClient.post('/documents/create-text', file)
        .then(function(response){
          vm.$root.$emit('saveSuccess', 'Changes saved');
          vm.loadDocuments();
        })
        .catch(function(error){
          vm.$root.$emit('saveFailed', 'Unable to create document');
        });
    },
    uploadDocuments(files){
      const vm = this;
      vm.dialog = false;

      if(files.length === 0) return;

      let data = new FormData();

      let model = {};
      model.studentToken = vm.studentToken;

      let metadata = [];

      files.forEach((x, idx) => {
        if(!x.deleted){
          data.append('photos', x.image, x.name);
          metadata.push({
            name: x.name,
            description: x.description
          });
        }
      });

      model.photoMetadata = metadata;
      data.append('model', JSON.stringify(model));

      httpClient.post('/documents/create', data)
        .then(function(response){
          if(response.data.success){
            vm.loadDocuments();
            vm.$root.$emit('saveSuccess', {message: "Save Successful"});
          }else{
            console.log(response);
            vm.$root.$emit('saveFailed', {message: response.data.message});
          }

        })
        .catch(function(error){
          console.log(error);
          vm.$root.$emit('saveFailed', {message: 'We apologize, an error has occurred. Please try again later.'});
        });
    },
    documentChanged(file){
      const vm = this;

      httpClient.post('/documents/update', file)
      .then(function(response){
        vm.$root.$emit('saveSuccess', {message: 'Your changes were saved'});
      })
    },
    remove(file){
      const vm = this;
      if(file.isService){
        Swal.fire({
          title: 'This document is part of a Service Log.  Delete it?',
          icon:'warning',
          showDenyButton: true,
          showCancelButton: false,
          showCloseButton: false,
          confirmButtonText: 'Yes',
          denyButtonText: 'No'
        }).then((result) => {
          if(result.isConfirmed){
            vm.deleteDocument(file);
          }
        })
      }else{
        vm.deleteDocument(file);
      }
    },
    deleteDocument(file){
      const vm = this;

      localStorage.setItem('lastDeletedDocument', file.id);

      httpClient.post('/documents/remove', {studentToken: vm.studentToken, documentId: file.id})
        .then(function(response){
          vm.deletedAlert.message = `${file.name} deleted.`;
          vm.deletedAlert.visible = true;
          vm.loadDocuments();

        })
        .catch(function(error){
          vm.$root.$emit('saveFailed', 'Unable to delete this file');
        });
    },
    undoLastDelete(){
      const vm = this;

      const id =  localStorage.getItem('lastDeletedDocument');

      httpClient.post('/documents/undo-remove', {studentToken: vm.studentToken, documentId: parseInt(id)})
        .then(function(response){
          vm.deletedAlert.visible = false;
          vm.loadDocuments();
        })
        .catch(function(error){
          vm.$root.$emit('saveFailed', 'Unable to restore this file');
        });
    },
    save(){
      const vm = this;

      httpClient.post('/student', vm.student)
        .then(function(response){
        vm.$root.$emit('saveSuccess', {message: 'Your changes were saved'});
        vm.$router.push('/admin/students');
      })
      .catch(function(response){
        vm.$root.$emit('saveFailed', {message: 'Student could not be saved'});
      });
    },
    cancel(){
      this.$router.push('/admin/students');
    },
    searchDocuments(e) {
      const vm = this;
      if (e.keyCode === 13) {
        if (e.search === '' || e.search === undefined) {
          vm.loadDocuments();
        } else {
          httpClient.get(`/documents/${vm.student.token}/search/${e.search}`)
            .then(function (response) {
              vm.documents = response.data;
            });
        }
      }
    },
    loadDocuments(){
      const vm = this;
      httpClient.get(`/documents/${vm.student.token}`)
        .then(function(response){
          vm.documents = response.data;
        });
    }
  },
  mounted(){
    const vm = this;
    vm.id = this.$route.params.id;

    httpClient.get(`/student/${vm.id}`)
      .then(function(response){
        vm.student = response.data;
        console.log(vm.student.summerProject);
        store.commit('app/setStudentToken', vm.student.token);

        vm.loadDocuments();

      })
      .catch(function(error){
        console.log(error);
      });


  }
}
</script>

<style scoped>

</style>
