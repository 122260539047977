<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-col cols="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" :to="{name: 'create-organization'}">Add New Organization</v-btn>
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="organizations"
        :items-per-page="10"
        :search="search"
        class="flex-grow-1"
      >
        <template v-slot:item.action="{ item }">
          <div class="actions">
            <v-btn text color="primary" :to="{name: 'modify-organization', params:{id: item.id}}">
              Edit
            </v-btn>
            <v-btn text color="error"  @click="remove(item)">
              Remove
            </v-btn>
          </div>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
  name: "OrganizationList",
  props:['organizations'],
  data(){
    return {
      search: null,
      headers:[
        {text: 'Organization Name', value: 'name'},
        {text: 'Address', value: 'address'},
        {text: 'City', value: 'city'},
        {text: 'State', value: 'state'},
        {text: 'Zip', value: 'zip'},
        {text: 'Phone', value: 'phone'},
        { text: '', sortable: false, align: 'right', value: 'action', filterable: false, }
      ],
    }
  },
  methods:{
    remove(item){
      const vm = this;
      const popup = Swal.mixin({
        customClass:{
          confirmButton: 'v-btn v-btn-success btn btn-succcess',
          cancelButton: 'v-btn v-btn--text theme--light v-size--default'
        },
        buttonsStyling: false
      })

      Swal.fire({
        title: `Do you want to delete the organization ${item.name}?`,
        icon:'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if(result.isConfirmed){
          vm.$emit('remove', item);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
