<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Student Bulk Load</h1>
    </div>

    <v-row>
      <v-col cols="3">
        <v-btn  color="blue-grey" class="white--text" @click="downloadTemplate"><v-icon>mdi-cloud-download-outline</v-icon> &nbsp; Download Bulk Template</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <file-upload-button  @change="loadTemplate" button-text="Upload Template and Create Students" @opened="fileDialogOpened" :loading="loading" :files="templates" :hideTypes="true"><v-icon>mdi-cloud-upload-outline</v-icon></file-upload-button>
      </v-col>
    </v-row>

  <v-row v-if="showGrid">
    <v-col cols="12" >
      <v-card>
        <v-card-text>
          <v-card flat>
            <v-data-table
              :headers="headers"
              :items="students"
              :items-per-page="100"
              class="flex-grow-1"
            >
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import FileUploadButton from "@/components/file/FileUploadButton";
import httpClient from "@/plugins/axios";
export default {
  name: "StudentBulkLoad",
  components:{FileUploadButton},
  data(){
    return {
      loading: false,
      showGrid: false,
      templates: [],
      headers:[
        {text: 'First Name', value: 'firstName'},
        {text: 'Last Name', value: 'lastName'},
        {text: 'Email', value:'email'},
        {text: 'Graduation Date', value:'graduationDate'},
        {text: 'Result', value:'message'}
      ],
      students: []
    }
  },
  methods:{
    downloadTemplate(){
      httpClient.get('/student/get-bulk-template', {responseType: 'blob'})
        .then(response =>{
          const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'Ingram Service Log Student Bulk Load Template.xlsx';
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
    fileDialogOpened(){
      this.templates = [];
    },
    loadTemplate(){
      this.loading = true;

      const vm = this;
      let data = new FormData();

      data.append('file', vm.templates[0].image);

      httpClient.post('/student/load-template', data)
      .then(response => {
        vm.loading = false;
        vm.students = response.data;
        vm.showGrid = true;
      })
      .catch(error => {
        vm.loading = false;
      });

    }
  }
}
</script>

<style scoped>

</style>
