<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-col cols="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" :to="{name: 'create-category'}">Add New Category</v-btn>
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="categories"
        :items-per-page="10"
        :search="search"
        class="flex-grow-1"
      >
        <template v-slot:item.action="{ item }">
          <div class="actions">
            <v-btn text color="primary" :to="{name: 'modify-category', params:{id: item.id}}">
              Edit
            </v-btn>
            <v-btn text color="error"  @click="remove(item)">
              Remove
            </v-btn>
          </div>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
  name: "CategoryList",
  props:['categories'],
  data(){
    return {
      search: null,
      headers:[
        {text: 'Category Name', value: 'name'},
        { text: '', sortable: false, align: 'right', value: 'action', filterable: false, }
      ],
    }
  },
  methods:{
    remove(item){
      const vm = this;
      Swal.fire({
        title: `You are about to delete the category ${item.name}.  Continue?`,
        icon:'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if(result.isConfirmed){
          vm.$emit('remove', item);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
