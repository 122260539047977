<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="validContacts"
      :items-per-page="10"
      class="flex-grow-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >

          <v-toolbar-title></v-toolbar-title>

          <v-spacer></v-spacer>


        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Add Contact
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{action}}</span>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="6"
                      sm="4"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.firstName"
                        label="First Name"
                        :rules="[v => !!v || 'First Name is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="6"
                      sm="4"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.lastName"
                        label="Last Name"
                        :rules="[v => !!v || 'Last Name is required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.title"
                        label="Title"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.phone"
                        label="Phone"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <div class="actions">
          <v-btn text color="primary" @click="edit(item)">
            Edit
          </v-btn>
          <v-btn text color="error"  @click="remove(item)">
            Remove
          </v-btn>
        </div>
      </template>

    </v-data-table>
  </div>

</template>

<script>
import Swal from 'sweetalert2';
export default {
  name: "ContactList",
  props:['contacts'],
  data(){
    return {
      valid: true,
      dialog: false,
      editedIndex: -1,
      editedItem:{
        id:0,
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        phone: '',
        deleted: false
      },
      defaultItem:{
        id:0,
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        phone: '',
        deleted: false
      },
      headers:[
        {text: 'First Name', value: 'firstName'},
        {text: 'Last Name', value: 'lastName'},
        {text: 'Title', value: 'title'},
        {text: 'Email', value: 'email'},
        {text: 'Phone', value: 'phone'},
        { text: '', sortable: false, align: 'right', value: 'action', filterable: false, }
      ],
    }
  },
  computed: {
    action () {
      return this.editedIndex === -1 ? 'New Contact' : 'Edit Contact';
    },
    validContacts(){
      if(this.contacts === undefined) return [];
      return this.contacts.filter(function(item){return item.deleted === false;});
    }
  },
  methods:{
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    edit(item){
      this.editedIndex = this.contacts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save () {
      let isValid = this.$refs.form.validate();
      if(!isValid) return;

      if (this.editedIndex > -1) {
        Object.assign(this.contacts[this.editedIndex], this.editedItem)
      } else {
        this.contacts.push(this.editedItem)
      }

      this.$emit('dirty');

      this.close()
    },
    remove(item){
      const vm = this;

      Swal.fire({
        title: `Do you want to delete ${item.firstName} ${item.lastName}?`,
        icon:'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if(result.isConfirmed){
          if(item.id === 0){
            let idx = this.contacts.indexOf(item);
            vm.contacts.splice(idx, 1);
          }else{
            item.deleted = true;
            this.$emit('dirty');
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
