<template>
  <v-card>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols="6" md="4">
            <v-text-field v-model="student.firstName"
                          label="First Name"
                          required
                          :rules="[v => !!v || 'First Name is required']"
                          outlined
                          dense></v-text-field>
          </v-col>

          <v-col cols="6" md="4">
            <v-text-field v-model="student.lastName"
                          label="Last Name"
                          required
                          :rules="[v => !!v || 'Last Name is required']"
                          outlined
                          dense></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="4">
            <v-text-field v-model="student.email"
                          label="Email"
                          required
                          :rules="emailRules"
                          outlined
                          @blur="checkEmailExists"
                          dense></v-text-field>
          </v-col>

          <v-col cols="6" md="4">
            <v-menu ref="menu1"
                    v-model="showDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="formatGraduationDate"
                              label="Graduation Date"
                              hint="MM/DD/YYYY format"
                              persistent-hint
                              autocomplete="off"
                              v-bind="attrs"
                              v-on="on"
                              required
                              :rules="[v => !!v || 'Graduation Date is required']"
                              outlined
                              dense></v-text-field>
              </template>
              <v-date-picker v-model="student.graduationDate"
                             required
                             no-title
                             label="Graduation Date"
                             @input="showDatePicker = false"
                             outlined
                             dense></v-date-picker>
            </v-menu>


          </v-col>


        </v-row>

        <v-row v-if="showArchived">
          <v-col cols="6" md="4">
            <v-checkbox
              v-model="student.archived"
              label="Archived"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" @click="save">Done</v-btn>
      <v-btn text @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import httpClient from "@/plugins/axios";
import store from '@/store';

export default {
  name: "StudentDetails",
  props:{student: Object, showArchived: Boolean},
  data: vm => {
    return {
      valid: true,
      showDatePicker: false,
      emailIsUnique: true,
      emailRules: [
        v => !!v || 'Email is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Email must be valid',
        vm.emailExistsRule
      ]
    }
  },
  computed:{
    formatGraduationDate(){
      return this.$luxon(this.student.graduationDate, "date_short");
    },
  },
  methods:{
    emailExistsRule(){
      return this.emailIsUnique ? true : 'This email is already in use';
    },
    checkEmailExists(){
      const vm = this;

      store.dispatch('validation/IsEmailUnique', {email: vm.student.email})
      .then(function(response){
        vm.emailIsUnique = response;
        vm.$refs.form.validate();
      });

    },
    save(){
      const vm = this;


      let isValid = this.$refs.form.validate();
      if(!isValid) return;

      this.$emit('save');

    },
    cancel(){
      this.$emit('cancel');
    }
  }
}
</script>

<style scoped>

</style>
