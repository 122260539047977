<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Create Admin User</h1>
    </div>
    <admin-details :admin="admin" @save="save" @cancel="cancel"></admin-details>
  </div>
</template>

<script>
import AdminDetails from "@/components/ingram/admin/AdminDetails";
import httpClient from "@/plugins/axios";
export default {
  name: "CreateAdmin",
  components: {AdminDetails},
  data(){
    return{
      admin:{}
    }
  },
  methods:{
    save(){
      const vm = this;
      httpClient.post('/admin', vm.admin)
        .then(function(response){
          if(response.data.success){
            vm.$root.$emit('saveSuccess', {message: 'Your changes were saved'});
            vm.$router.push('/admin/admins');
          }
          else{
            vm.$root.$emit('saveFailed', {message: 'Admin user could not be saved'});
          }

        })
        .catch(function(response){
          vm.$root.$emit('saveFailed', {message: 'Admin user could not be saved'});
        });
    },
    cancel(){
      this.$router.push('/admin/admins');
    }
  },
  mounted(){
  }
}
</script>

<style scoped>

</style>
