<template>
  <v-card flat>
    <v-simple-table >
      <template v-slot:default>
        <thead>
        <tr>
          <th>Organization / Contact</th>
          <th style="width:600px;">Description</th>
          <th>Date</th>
          <th>Hours</th>
          <th>Type</th>
          <th>Category</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="s in service" :key="s.id">
          <td class="at">
            {{s.organization}}
            <br/>
            {{s.contact}}
          </td>
          <td>{{s.description}}</td>
          <td class="at">{{s.date | luxon({output: {format:'MM/dd/yyyy'} }) }}</td>
          <td class="at">{{s.hours}}</td>
          <td class="at">{{s.type}}</td>
          <td class="at">{{s.category}}</td>
        </tr>
        </tbody>

      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ReportServiceList",
  props:{
    service:Array
  },
  computed:{
    ...mapGetters({
      isAdmin: 'app/isAdmin'
    }),
  },
  data(){
    return{
    }
  }
}
</script>

<style scoped>
  .at{
    vertical-align: top;
  }
</style>
