<template>
  <div>

    <v-card flat>
      <v-card-title>
        <v-col cols="5">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            v-model="showArchived"
            label="Show Archived"
          ></v-checkbox>
        </v-col>
        <v-col cols="5">
          <v-btn color="primary" :to="{name: 'create-student'}">Add New Student</v-btn>
          &nbsp;
          <v-btn color="blue-grey" class="white--text" :to="{name: 'student-bulk-load'}">Load Students From Spreadsheet</v-btn>
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="visibleStudents"
        :items-per-page="10"
        :search="search"
        class="flex-grow-1"
      >
        <template v-slot:item.action="{ item }">
          <div class="actions">
            <v-btn text color="primary" :to="{name: 'modify-student', params:{id: item.id}}">
              Edit
            </v-btn>
            <v-btn v-if="item.lastLoginDate === ''" text color="warning" @click="resendEmail(item.id)">Resend Email</v-btn>
          </div>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "StudentList",
  props:['students'],
  data(){
    return {
      search: null,
      showArchived: false,
      headers:[
        {text: 'First Name', value: 'firstName'},
        {text: 'Last Name', value: 'lastName'},
        {text: 'Email', value:'email'},
        {text: 'Graduation Date', value:'graduationDate'},
        {text: 'Last Login', value:'lastLoginDate', filterable: false,},
        { text: '', sortable: false, align: 'right', value: 'action', filterable: false, }
      ],
    }
  },
  computed:{
    visibleStudents(){
      const vm = this;
      if(!vm.showArchived){
        return vm.students.filter((x) => x.archived === false);
      }

      return vm.students;
    }
  },
  methods:{
    resendEmail(id){
      this.$emit('resendEmail', id)
    }
  }
}
</script>

<style scoped>

</style>
