<template>
  <v-card>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols="8" md="6">
            <v-text-field v-model="organization.name"
                          label="Organization Name"
                          required
                          :rules="[v => !!v || 'Organization Name is required']"
                          outlined
                          dense
                          @change="dirty"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="4">
            <v-text-field v-model="organization.address"
                          label="Address"
                          required
                          outlined
                          dense
                          @change="dirty"></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field v-model="organization.address2"
                          label="Address 2"
                          required
                          outlined
                          dense
                          @change="dirty"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
        <v-col cols="6" md="4">
          <v-text-field v-model="organization.city"
                        label="City"
                        required
                        outlined
                        dense
                        @change="dirty"></v-text-field>
        </v-col>

        <v-col cols="2" md="2">
          <v-select v-model="organization.state"
                    :items="states"
                    item-text="name"
                    item-value="id"
                    label="State"
                    outlined
                    dense
                    @change="dirty"></v-select>
        </v-col>

        <v-col cols="4" md="2">
          <v-text-field v-model="organization.zip"
                        label="Zip"
                        required
                        outlined
                        dense
                        @change="dirty"></v-text-field>
        </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-text-field v-model="organization.website"
                          label="Website"
                          outlined
                          dense
                          @change="dirty"></v-text-field>
          </v-col>
        </v-row>

      </v-form>

      <v-row>
        <v-col cols="12">
          <v-divider class="my-2"></v-divider>
          <h1 class="text-h5">Contacts</h1>
          <v-divider class="my-2"></v-divider>
        </v-col>
      </v-row>

      <contact-list :contacts="organization.contacts" @dirty="dirty"></contact-list>

    </v-card-text>
    <v-card-actions>
      <v-btn color="success" @click="save">Done</v-btn>
      <v-btn text @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import states from '@/data/states';
import ContactList from "@/components/ingram/organization/ContactList";

export default {
  name: "OrganizationDetails",
  components: {ContactList},
  props:['organization'],
  data(){
    return {
      valid: true,
      states: states,
    }
  },
  methods:{
    save(){
      let isValid = this.$refs.form.validate();
      if(!isValid) return;

      this.$emit('save');
    },
    cancel(){
      this.$emit('cancel');
    },
    dirty(){
      this.$emit('dirty');
    }
  },


}
</script>

<style scoped>

</style>
