<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Create Category</h1>
    </div>
    <category-details :category="category" @save="save" @cancel="cancel"></category-details>
  </div>
</template>

<script>
import CategoryDetails from "@/components/ingram/categories/CategoryDetails";
import httpClient from "@/plugins/axios";
import store from "@/store";
export default {
  name: "CreateCategory",
  components: {CategoryDetails},
  data(){
    return {
      category:{name: null}
    }
  },
  methods:{
    save(){
      const vm = this;

      httpClient.post('/category', vm.category)
        .then(function(response){
          if(response.data.success){
            vm.$root.$emit('saveSuccess', {message: 'Your changes were saved'});
            store.dispatch("app/getDomainData");
            vm.$router.push('/admin/categories');
          }else{
            vm.$root.$emit('saveFailed', {message: response.data.message});
          }

        })
        .catch(function(response){
          vm.$root.$emit('saveFailed', {message: 'Category could not be saved'});
        });
    },
    cancel(){
      this.$router.push('/admin/categories');
    }
  },
  mounted(){
    const vm = this;
    vm.id = this.$route.params.id;

    /*
    httpClient.get(`/category/${vm.id}`)
      .then(function(response){
        vm.category = response.data;
      })
      .catch(function(error){
        console.log(error);
      });

     */
  }
}

</script>

<style scoped>

</style>
