<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Modify Student</h1>
    </div>
    <admin-details :admin="admin" @save="save" @cancel="cancel"></admin-details>
  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import AdminDetails from "@/components/ingram/admin/AdminDetails";
export default {
  name: "EditAdmin",
  components:{AdminDetails},
  data(){
    return{
      showArchived: true,
      admin:{},
    }
  },

  methods:{
    save(){
      const vm = this;

      httpClient.post('/admin', vm.admin)
        .then(function(response){
          vm.$root.$emit('saveSuccess', {message: 'Your changes were saved'});
          vm.$router.push('/admin/admins');
        })
        .catch(function(response){
          vm.$root.$emit('saveFailed', {message: 'Admin user could not be saved'});
        });
    },
    cancel(){
      this.$router.push('/admin/admins');
    }
  },
  mounted(){
    const vm = this;
    vm.id = this.$route.params.id;

    httpClient.get(`/admin/${vm.id}`)
      .then(function(response){
        vm.admin = response.data;
      })
      .catch(function(error){
        console.log(error);
      });
  }
}
</script>

<style scoped>

</style>
